import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import heroImage from "../../assets/Hero.png";

const Hero = ({ img, title, description, paragraph }) => {
  return (
    <section className="py-4 py-xl-5 ">
      <Container className="h-100">
        <Row className="d-md-flex h-100">
          <Col
            md={5}
            xl={6}
            className="text-center d-flex justify-content-center align-items-center mx-auto justify-content-md-start align-items-md-center justify-content-xl-center"
          >
            <div>
              <h2 className=" text-uppercase fw-bold text-end d-md-flex mb-3">
                <strong>
                  <span
                    className="font-readex"
                    style={{ color: "rgb(46, 47, 61)" }}
                  >
                    {title}
                  </span>
                </strong>
              </h2>
              <p className="text-end mb-4 font-readex">
                <strong>
                  <span style={{ color: "rgb(46, 47, 61)" }}>
                    {description}
                  </span>
                </strong>
              </p>
              <p className="fw-light text-end mb-4">
                <strong>
                  <span style={{ color: "rgb(106, 106, 106)" }}>
                    {paragraph}
                  </span>
                </strong>
                <br />
              </p>
              {/* <Button
                variant="warning"
                className="text-bg-warning d-md-flex justify-content-md-start"
              >
                قم بتمرير الرمز الخاص بك بسرعة{" "}
              </Button> */}
            </div>
          </Col>
          <Col
            md={5}
            xl={6}
            className="text-center d-flex justify-content-center align-items-center mx-auto justify-content-md-start align-items-md-center justify-content-xl-center"
          >
            <img
              style={{ borderRadius: "30px" }}
              src={img}
              width="300px"
              alt="Hero"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Hero;
