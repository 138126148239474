import "./App.css";
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";

import Result from "./components/result/result";
import QuestionAnswer from "./components/questionAnswer/QuestionAnswer";
import Login from "./components/auth/login";
import Register from "./components/auth/Register";
import NavBar from "./components/navBar/navBar";
import Series from "./pages/seriesPage/Series";
import Footer from "./components/footer/footer";
import HomePage from "./pages/homePage/homePage";
import QuizType from "./pages/quiz/quizType";
import ExamQuiz from "./pages/quiz/examQuiz";
import TrainingQuiz from "./pages/quiz/trainingQuiz";
import TermsOfService from "./pages/TermsOfService/TermsOfService";
import NotFoundPage from "./pages/notFoundPage/notFoundPage";

function App() {
  const token = localStorage.getItem("token");
  return (
    <Router>
      <MainLayout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/series" element={<Series />} />
          <Route path="/quiz" element={<QuizType />} />
          <Route path="/result" element={<Result />} />
          <Route path="/QuestionAnswer/:id" element={<QuestionAnswer />} />
          <Route path="/login" element={<Login />} />
          {/* <Route path="/register" element={<Register />} /> */}
          <Route path="/TermsOfService" element={<TermsOfService />} />
          {/* Protected routes for quizzes */}
          <Route
            path="/quiz/exam"
            element={<ProtectedRoute token={token} component={<ExamQuiz />} />}
          />
          <Route
            path="/quiz/training"
            element={
              <ProtectedRoute token={token} component={<TrainingQuiz />} />
            }
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </MainLayout>
    </Router>
  );
}

const ProtectedRoute = ({ token, component }) => {
  return token ? component : <Navigate to="/login" />;
};

function MainLayout({ children }) {
  const location = useLocation();

  const hideNavBarAndFooter = ["/quiz/exam", "/quiz/training"].includes(
    location.pathname
  );

  return (
    <>
      {!hideNavBarAndFooter && <NavBar />}
      {children}
      {!hideNavBarAndFooter && <Footer />}
    </>
  );
}

export default App;
