import React, { useState, useEffect } from "react";
import { getOneQuestion } from "../../redux/actions/questionAction";
import { useDispatch, useSelector } from "react-redux";

const GetOneQuestionHook = (id) => {
  const [question, setQuestion] = useState([]);

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  useEffect(() => {
    const get = async () => {
      setLoading(true);
      await dispatch(getOneQuestion(id));
      setLoading(false);
    };
    get();
  }, []);
  const res = useSelector((state) => state.question.oneQuestion);
  useEffect(() => {
    if (!loading) {
      // console.log(res);
      if (res && res.data) {
        setQuestion(res.data);
      }
    }
  }, [loading]);
  // console.log(questions);
  // console.log(totalPages);
  // console.log(currentPage);
  return [question];
};

export default GetOneQuestionHook;
