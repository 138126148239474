import React from "react";
import logo from "../../assets/logo.png";
import { FaWhatsapp } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

const NavBar = () => {
  return (
    <nav
      style={{
        position: "sticky",
        top: "0",
        left: "0",
        right: "0",
        width: "100%",
        zIndex: "1030",
      }}
      className="navbar navbar-expand-md py-3"
    >
      <div className="container">
        <div className="d-flex">
          <a
            style={{ marginLeft: "30px" }}
            className="navbar-brand d-flex align-items-center"
            href="/"
          >
            <span className="bs-icon-sm bs-icon-rounded bs-icon-primary d-flex justify-content-center align-items-center bs-icon ">
              <img style={{ maxWidth: "100%" }} src={logo} alt="" />
            </span>
          </a>
          <button
            data-bs-toggle="collapse"
            className="navbar-toggler"
            data-bs-target="#navcol-5"
            aria-controls="navcol-5"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div className="collapse navbar-collapse" id="navcol-5">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <a className="nav-link active text-dark" href="/series">
                السلاسل
              </a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link text-dark" href="/lessons">
                الدروس
              </a>
            </li> */}
            <li className="nav-item">
              <a className="nav-link text-dark" href="#">
                تعليمات الإستخدام
              </a>
            </li>
          </ul>
          <a
            className="btn btn-primary text-bg-success border-0"
            role="button"
            href="/login"
          >
            التسجيل
          </a>
          {/* <a
            className="text-dark fs-2"
            href="https://wa.me/+212613294385"
            target="_blank"
          >
            <FaWhatsapp />
          </a>
          <a className="text-dark fs-2 me-2" href="">
            <MdEmail />
          </a> */}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
