import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Container } from "react-bootstrap";
import GetSeriesHook from "../../hook/questions/get-series-hook";
import { FcStatistics } from "react-icons/fc";

import icon from "../../assets/Traffic Light Street.png";
import serie1 from "../../assets/Serie1.png";
import serie2 from "../../assets/Serie2.png";
import serie5 from "../../assets/Mechanic1.png";

const Questions = () => {
  // const [questions, totalPages, currentPage] = GetSeriesHook();
  // console.log(questions);

  // const totalSeries = 20;
  const totalSeries = [
    {
      icon: serie1,
      active: true,
      title: "البلايك 1",
    },
    {
      icon: serie2,
      active: true,
      title: "البلايك 2",
    },
    {
      icon: "",
      active: true,
      title: "",
    },
    {
      icon: "",
      active: true,
      title: "",
    },
    {
      icon: serie5,
      active: true,
      title: "الميكانيك",
    },
    {
      icon: "",
      active: true,
      title: "",
    },
    {
      icon: "",
      active: true,
      title: "",
    },
    {
      icon: "",
      active: true,
      title: "",
    },
    {
      icon: "",
      active: true,
      title: "",
    },
    {
      icon: "",
      active: true,
      title: "",
    },
    {
      icon: "",
      active: true,
      title: "",
    },
    {
      icon: "",
      active: true,
      title: "",
    },
    {
      icon: "",
      active: true,
      title: "",
    },
    {
      icon: "",
      active: false,
      title: "",
    },
    {
      icon: "",
      active: false,
      title: "",
    },
    {
      icon: "",
      active: false,
      title: "",
    },
    {
      icon: "",
      active: false,
      title: "",
    },
    {
      icon: "",
      active: false,
      title: "",
    },
    {
      icon: "",
      active: false,
      title: "",
    },
    {
      icon: "",
      active: false,
      title: "",
    },
  ];

  return (
    <Container>
      <Row className="gy-4 row-cols-1 row-cols-md-2 row-cols-xl-3">
        {totalSeries.map((item, index) => (
          <Col key={index}>
            <Link
              to={item.active ? `/quiz?questionSerie=${index + 1}` : "/series"}
              className="text-decoration-none"
            >
              <Card
                className="text-center d-flex flex-column align-items-center"
                style={
                  item.active
                    ? {}
                    : {
                        filter: "grayscale(1)",
                        backgroundColor: "#cacaca",
                        cursor: "not-allowed",
                      }
                }
              >
                <div
                  className="bs-icon-lg bs-icon-rounded bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-3"
                  style={{ minHeight: "120px" }}
                >
                  {/* <Card.Img
                    variant="top"
                    src={}
                    className="bi bi-icon-class"
                  /> */}
                  <img
                    style={{ width: "100%", height: "100%", marginTop: "20px" }}
                    src={item.icon === "" ? icon : item.icon}
                    alt=""
                  />
                  {/* <FcStatistics style={{ fontSize: "64px" }} /> */}
                </div>
                <Card.Body>
                  <Card.Title style={{ fontWeight: "bold" }}>
                    {`إختبار رخصة السياقة - السلسة رقم : ${index + 1}`}
                  </Card.Title>
                  <Card.Text style={{ fontWeight: "600", color: "#666" }}>
                    {item.title === "" ? "تلقائي" : item.title}
                  </Card.Text>
                  <Card.Text
                    style={{ width: "45%", margin: "auto" }}
                    className="text-bg-warning fw-bold rounded-pill"
                  >
                    إبدأ الآن
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Questions;
