import React, { useEffect, useRef } from "react";

const AudioPlayer = ({ audioUrl, onAudioEnded, muted }) => {
  const audioRef = useRef(null);

  useEffect(() => {
    const audioElement = audioRef.current;
    // console.log(audioElement.currentTime);
    // Restore playback position from localStorage if available
    const savedTime = localStorage.getItem("audioPlaybackTime");
    if (audioElement) {
      if (savedTime) {
        audioElement.currentTime = parseFloat(savedTime);
      }

      // Start playing the audio
      audioElement.play().catch((error) => {
        console.error("Error playing audio:", error);
      });

      // Handle time updates to save the playback position
      const handleTimeUpdate = () => {
        localStorage.setItem("audioPlaybackTime", audioElement.currentTime);
      };

      audioElement.addEventListener("timeupdate", handleTimeUpdate);

      // Clean up event listener on component unmount
      return () => {
        audioElement.removeEventListener("timeupdate", handleTimeUpdate);
        // Optionally clear playback position if needed
        localStorage.removeItem("audioPlaybackTime");
      };
    }
  }, [audioUrl]);

  const handleAudioEnded = () => {
    if (onAudioEnded) {
      onAudioEnded();
    }
    // Clear the playback position when audio ends
    localStorage.removeItem("audioPlaybackTime");
  };

  return (
    <audio
      ref={audioRef}
      src={audioUrl}
      preload="auto"
      autoPlay
      onEnded={handleAudioEnded}
      muted={muted}
    />
  );
};

export default AudioPlayer;
