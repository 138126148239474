import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Alert from "../utils/alert";
import LoginHook from "../../hook/auth/login-hook";
import { ToastContainer } from "react-toastify";
import logo from "../../assets/logo-mini.png";

const Login = () => {
  const [
    onChangeEmail,
    onChangePassword,
    email,
    password,
    loading,
    onSubmit,
    isPress,
  ] = LoginHook();
  return (
    <section
      className="position-relative py-4 py-xl-5 "
      style={{ height: "calc(100vh - 100px)" }}
    >
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-8 col-xl-6 text-center mx-auto">
            <h2>
              <span style={{ color: "rgba(0, 0, 0, 0.85)" }}>
                مرحبا بك في منصة الكود
              </span>
            </h2>
            <p className="w-lg-50">
              <span style={{ color: "rgb(166, 166, 166)" }}>
                سجل دخولك للولوج إلى المنصة
              </span>
            </p>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-md-6 col-xl-4">
            <div className="card mb-5">
              <div className="card-body d-flex flex-column align-items-center">
                <img
                  width="80"
                  height="80"
                  src={logo}
                  alt="Logo"
                  className="mb-5"
                />
                <div className="mb-3">
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    value={email}
                    onChange={onChangeEmail}
                    placeholder="الإيمايل"
                  />
                </div>
                <div className="mb-3">
                  <input
                    className="form-control"
                    type="password"
                    name="password"
                    value={password}
                    onChange={onChangePassword}
                    placeholder="كلمة السر"
                  />
                </div>
                <div className="mb-3">
                  <button
                    className="btn btn-primary d-block w-100"
                    onClick={onSubmit}
                    type="submit"
                  >
                    الدخول
                  </button>
                </div>
                {/* <a href="/register" className="text-muted">
                  إنشاء حساب
                </a> */}
              </div>
            </div>
            <div className="m-auto" style={{ width: "fit-content" }}>
              الحصول على حساب :{" "}
              <a href="https://wa.me/+212613294385" target="_blank">
                0613294385
              </a>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default Login;
