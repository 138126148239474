import { USER_LOGIN, USER_REGISTER, GET_LOGGED_USER_DATA } from "../type";

import { useInsertData } from "../../hooks/useInsertData";
import { useInsUpdateData } from "../../hooks/useUpdateData";
import { useGetDataToken } from "../../hooks/useGetData";

// login action creator
export const login = (data) => async (dispatch) => {
  try {
    const response = await useInsertData(`/api/v1/auth/login`, data);
    dispatch({
      type: USER_LOGIN,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: USER_LOGIN,
      payload: e.response,
    });
  }
};

// signUp action creator
export const signUp = (data) => async (dispatch) => {
  try {
    const response = await useInsertData(`/api/v1/auth/register`, data);
    dispatch({
      type: USER_REGISTER,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: USER_REGISTER,
      payload: e.response,
    });
  }
};

// GEt looged user data
export const getLoggedUserData = () => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/users/getMe`);
    dispatch({
      type: GET_LOGGED_USER_DATA,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: GET_LOGGED_USER_DATA,
      payload: e.response,
    });
  }
};
