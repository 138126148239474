import { USER_LOGIN, USER_REGISTER, GET_LOGGED_USER_DATA } from "../type";

const initial = {
  register: [],
  login: [],
  logggedUser: [],
  loading: true,
};
const authReducer = (state = initial, action) => {
  switch (action.type) {
    case USER_REGISTER:
      return {
        ...state,
        register: action.payload,
      };
    case USER_LOGIN:
      return {
        ...state,
        login: action.payload,
      };
    case GET_LOGGED_USER_DATA:
      return {
        ...state,
        logggedUser: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
