import React from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";

const Footer = () => {
  return (
    <footer
      style={{
        direction: "ltr",
        backgroundColor: "var(--dark-color)",
      }}
      className="text-center bg-dark text-white py-4 py-lg-5"
    >
      <div className="container">
        <ul className="list-inline mb-4">
          <li className="list-inline-item me-4">
            <Link className="link-light" to="/TermsOfService">
              شروط الإستخدام
            </Link>
          </li>
        </ul>
        <ul className="list-inline mb-4 ">
          <li className="list-inline-item me-4">
            <a
              className="link-light"
              href="https://www.facebook.com/profile.php?id=61558066622408"
              target="_blank"
              aria-label="Facebook"
            >
              <FaFacebook />
            </a>
          </li>
          <li className="list-inline-item me-4">
            <a
              className="link-light"
              href="https://www.tiktok.com/@almoniteur "
              target="_blank"
              aria-label="Twitter"
            >
              <FaTiktok />
            </a>
          </li>
          <li className="list-inline-item me-4">
            <a
              className="link-light"
              href="https://www.instagram.com/almoniteur/"
              target="_blank"
              aria-label="Instagram"
            >
              <FaInstagram />
            </a>
          </li>
          <li className="list-inline-item">
            <a
              className="link-light"
              href="https://www.youtube.com/@Almoniteur"
              target="_blank"
              aria-label="Instagram"
            >
              <FaYoutube />
            </a>
          </li>
        </ul>
        <p className="text-light mb-0">
          Copyright © 2024&nbsp;
          <a
            href="https://www.instagram.com/ahmed_dev13/"
            className="text-primary text-decoration-underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            @ahmed_dev13
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
