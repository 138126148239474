import axios from "axios";

const API_URL = "http://localhost:3001"; // Replace with your API URL
const register = (name, email, password, passwordConfirm) => {
  return axios
    .post(`${API_URL}/api/v1/auth/signup`, {
      name,
      email,
      password,
      passwordConfirm,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data.token));
      }
      return response.data;
    });
};

const login = (email, password) => {
  return axios
    .post(`${API_URL}/api/v1/auth/login`, {
      email,
      password,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data.token));
      }
      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const getLoggedUserData = async (token, errorRes, dataRes) => {
  try {
    const response = await axios.get(`${API_URL}/api/v1/auth/getMe`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dataRes(response.data.data);
  } catch (error) {
    if (error.response && error.response.data && error.response.data.errors) {
      // If errors array exists in response, extract and set error messages
      const errorMessages = error.response.data.errors.map((error) => error);
      errorRes(errorMessages[0].msg);
    }
  }
};
export { register, login, logout, getCurrentUser, getLoggedUserData };
