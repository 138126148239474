import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./components/auth/authContext";

import { Provider } from "react-redux";
import store from "./redux/store";

// function ErrorBoundary(props) {
//   const [hasError, setHasError] = useState(false);

//   const componentDidCatch = (error, errorInfo) => {
//     // Log the error to an error reporting service
//     console.error("Error caught by ErrorBoundary:", error, errorInfo);
//     // Update state to indicate an error has occurred
//     setHasError(true);
//   };

//   if (hasError) {
//     // Render fallback UI
//     return <div>Something went wrong.</div>;
//   }

//   // Render children if no error occurred
//   return props.children;
// }

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
